import { inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  #toastController = inject(ToastController);

  async createToast(
    message: string,
    duration: number = 3000,
    color:
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'success'
      | 'warning'
      | 'danger'
      | 'light'
      | 'medium'
      | 'dark' = 'dark',
    position: 'top' | 'bottom' | 'middle' = 'bottom'
  ): Promise<HTMLIonToastElement> {
    const toast = await this.#toastController.create({
      message,
      duration,
      color,
      position,
    });
    await toast.present();
    return toast;
  }
}
