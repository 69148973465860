import { ModelInterface } from '@interfaces/global/model.interface';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import { lastValueFrom } from 'rxjs';
import { AbstractService, ObservableParams } from './abstract.service';

export class AbstractHelper<IModel extends ModelInterface> {
  protected section: string;

  constructor(
    private _section: string | string[],
    private instance: AbstractService<IModel, QueryParamsInterface>
  ) {
    this.setSection(this._section);
  }

  private setSection(section: string | string[]): void {
    if (Array.isArray(section)) {
      this.section = section.length ? section[0] : 'standard';
      return;
    }
    this.section = section;
  }

  async getAsync<T>(obParams: ObservableParams): Promise<T>;
  async getAsync<T>(obParams: ObservableParams): Promise<T[]>;
  async getAsync<T>(obParams: ObservableParams = {}): Promise<T | T[]> {
    return lastValueFrom(
      this.instance.getObservable(this.section, obParams)
    ) as Promise<T | T[]>;
  }
}
