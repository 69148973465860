import {
  AssetResponseInterface,
  MarketDataInterface,
} from '@interfaces/asset/asset.response.interface';
import { RateResponseInterface } from '@interfaces/asset/rate/rate.response.interface';
import { Big } from 'big.js';

export interface ExtendedAsset extends AssetResponseInterface {
  percentChange?: Big;
  eurChange?: Big;
  image?: string;
}

export class EAsset implements ExtendedAsset {
  id: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  percentChange: Big = new Big(0);
  eurChange: Big = new Big(0);
  image: string = '';
  name: string = '';
  symbol: string = '';
  rate: RateResponseInterface = null;
  marketData: MarketDataInterface = null;

  constructor(asset: AssetResponseInterface) {
    Object.assign(this, asset);

    // IMAGE
    this.image = `assets/img/symbols/${this.symbol.toLowerCase()}.svg`;

    // PERCENT CHANGE
    this.percentChange = this.normalizePercentChange();

    // EUR CHANGE
    this.eurChange = this.calculateEurChange();
  }

  private calculateEurChange(): Big {
    let asset24hPercent = this.percentChange.div(100);
    return new Big(this.rate.EUR)
      .mul(asset24hPercent)
      .div(asset24hPercent.add(1));
  }

  private normalizePercentChange(): Big {
    const asset24hPercent = new Big(this.marketData.percentChange24h);
    return asset24hPercent.eq(0) ? new Big(0) : asset24hPercent;
  }

  create(): ExtendedAsset {
    return {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      percentChange: this.percentChange,
      eurChange: this.eurChange,
      image: this.image,
      name: this.name,
      symbol: this.symbol,
      rate: this.rate,
      marketData: this.marketData,
    };
  }
}
