import { MenuList } from '../header-menu.component';

export const MENU_WALLET: MenuList[] = [
  {
    label: 'admin.menu.asset',
    route: '/admin/asset/:assetId',
    translateParams: {
      asset: ':assetName',
    },
  },
  {
    label: 'admin.menu.buy',
    route: '/admin/wallet/:walletId/operation/buy',
    translateParams: {},
  },
  {
    label: 'admin.menu.sell',
    route: '/admin/wallet/:walletId/operation/sell',
    translateParams: {},
  },
  {
    label: 'admin.menu.alerts',
    route: '/admin/wallet/:walletId/alerts',
    translateParams: {},
  },
  {
    label: 'admin.menu.scheduled',
    route: '/admin/wallet/:walletId/scheduled-purchases',
    translateParams: {},
  },
  {
    label: 'admin.menu.send',
    route: '/admin/wallet/:walletId/withdraw',
    translateParams: {
      title: ':assetName',
    },
  },
  {
    label: 'admin.menu.recieve',
    route: '/admin/wallet/:walletId/deposit',
    translateParams: {
      title: ':assetName',
    },
  },
];
