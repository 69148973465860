import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  save(key: string, value: any): void {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value);
  }

  get<T>(key: string): T {
    const value = localStorage.getItem(key);
    return this.parseValue(value) as T;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  private parseValue(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error(e);
      return value;
    }
  }
}
