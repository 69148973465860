import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { IonicComponentsModule } from '@app/shared/modules/ionic-components/ionic-components.module';

// prettier-ignore
@Component({
  selector: 'app-card-box',
  standalone: true,
  imports: [CommonModule, IonicComponentsModule],
  template: `<ion-card class="card-box">
    <ion-card-header>
      <ion-card-title *ngIf="!!title()">{{ title() }}</ion-card-title>
      <ion-card-subtitle *ngIf="!!subtitle()">{{
        subtitle()
      }}</ion-card-subtitle>
      <ion-icon
        *ngIf="!!assetImage()"
        [src]="assetImage()"
        size="large"
        alt="asset"
        class="card-box__asset-image"
      />
    </ion-card-header>

    <ion-card-content class="card-box__content">
      <ng-content></ng-content>
    </ion-card-content>
  </ion-card>`,
})
export class CardBoxComponent {
  title = input<string>('');
  subtitle = input<string>('');
  assetImage = input<string>('');
}
