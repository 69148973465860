export class RateLimiter {
  // Last request date
  private lastRequestDate: Date;

  constructor(private seconds: number = 1) {
    // Le restamos 1 segundo para no tener problemas con el tiempo inicial de la aplicación.
    this.seconds = Math.max(1, seconds - 1);
  }

  isAllowed(): boolean {
    const now = new Date();

    if (!this.lastRequestDate) {
      this.lastRequestDate = now;
      return true;
    }

    const diffTime = now.getTime() - this.lastRequestDate.getTime();
    const diffSeconds = diffTime / 1000;
    const isAllowed = diffSeconds >= this.seconds;

    /*     console.log({
      isAllowed,
      diffSeconds,
      seconds: this.seconds,
    }); */

    if (isAllowed) this.lastRequestDate = now;

    return isAllowed;
  }
}
