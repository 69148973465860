import { Injectable } from '@angular/core';
import { ScheduledQueryParamsInterface } from '@interfaces/scheduled/scheduled.query.params.interface';
import { ScheduledResponseInterface } from '@interfaces/scheduled/scheduled.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduledService extends AbstractCrudService<
  ScheduledResponseInterface,
  ScheduledQueryParamsInterface
> {
  protected override feature = 'scheduled';
  protected override endPoint = '/schedules/';
}
