import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  AfterViewInit,
  Self,
  Optional,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Directive({
  selector: '[inputValidate]', // eslint-disable-line
})
export class InputValidatorDirective implements AfterViewInit {
  @Input() errorClass: string = 'input-error';
  @Input() inputControl!: AbstractControl;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() @Self() private control: AbstractControl
  ) {}

  ngAfterViewInit() {
    this.setup();
  }

  private setup(): void {
    if (!this.control && this.inputControl) {
      this.control = this.inputControl;
    } else {
      console.error(
        '[ERROR] inputValidate Directive: Si la directiva `inputValidate` no está en un elemento <input>, debe especificar el atributo `inputControl` con un control de formulário, ejemplo: `[inputControl]="loginForm.controls[\'email\']"  '
      );
      return;
    }
    this.applyEventListeners();
    this.applyValidationClasses();
    return;
  }

  private applyEventListeners(): void {
    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.applyValidationClasses());
  }

  private applyValidationClasses() {
    const isInvalid =
      this.control.invalid &&
      (this.control.dirty || this.control.touched);

    if (isInvalid) {
      this.renderer.addClass(this.el.nativeElement, this.errorClass);
    } else {
      this.renderer.removeClass(
        this.el.nativeElement,
        this.errorClass
      );
    }
  }
}
