import { ModuleWithProviders } from '@angular/core';
import { AbstractStore } from '../abstract/abstract-store.service';
import { BasicObservableService } from '../abstract/abstract.basic.service';
import { AlertsService } from './alerts/alerts.service';
import { AssetsService } from './assets/assets.service';
import { ErrorsService } from './error/errors.service';
import { GlobalErrorsService } from './global-errors/global-errors.service';
import { PoliciesService } from './policies/policies.service';
import { PurchasesService } from './purchases/purchases.service';
import { ScheduledService } from './scheduled/scheduled.service';
import { StatsService } from './stats/stats.service';
import { TransactionsService } from './transactions/transactions.service';
import { UsersService } from './users/users.service';
import { WalletTotalsService } from './wallet-totals/wallet-totals.service';
import { WalletsService } from './wallets/wallets.service';
import { WithdrawalsService } from './withdraw/withdraw.service';

export class AbstractServicesModules {
  static forRoot(): ModuleWithProviders<AbstractServicesModules> {
    return {
      ngModule: AbstractServicesModules,
      providers: [
        AbstractStore,
        BasicObservableService,
        ErrorsService,
        GlobalErrorsService,
        UsersService,
        AssetsService,
        WalletsService,
        TransactionsService,
        WalletTotalsService,
        StatsService,
        PurchasesService,
        ScheduledService,
        WithdrawalsService,
        AlertsService,
        PoliciesService,
      ],
    };
  }
}
