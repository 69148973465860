import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedRecord } from '@app/shared/interfaces/abstract/extended';
import {
  AuthErrors,
  GeneralErrors,
} from '@app/shared/interfaces/auth/auth.error.enum';
import { environment } from '@environments/environment';
import { TermsResponseInterface } from '@interfaces/policy/terms.response.interface';
import { GranTypeEnum } from '@interfaces/token/gran.type.enum';
import {
  CredentialsInterface,
  TokenResponseInterface,
} from '@interfaces/token/token.response.interface';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  take,
  tap,
  throwError,
} from 'rxjs';
import { UsersService } from '../abstract-services/users/users.service';
import { StorageService } from '../localstorage/local-storage.service';
import { AuthService, StorageKeys, Tokens } from './auth.service';
import { Monitor } from './monitor.class';

type Data<T> = HttpResponse<ExtendedRecord<T>>;
type TokenInterface = Tokens;
type IUser = UserResponseInterface;

@Injectable({ providedIn: 'root' })
export class AuthRefreshService extends AuthService {
  // Async Store
  override user$ = new BehaviorSubject<IUser | undefined>(undefined);
  override tokens$ = new BehaviorSubject<TokenInterface | undefined>(undefined);

  isRefreshing$ = new BehaviorSubject<boolean>(false);

  terms: TermsResponseInterface;

  // Monitor
  monitor: Monitor;

  constructor(
    protected override storage: StorageService,
    protected override http: HttpClient,
    protected override usersService: UsersService,
    protected override router: Router
  ) {
    super(storage, http, usersService, router);
    this.monitor = new Monitor(this.refresh.bind(this));
  }

  startMonitor(): void {
    this.monitor.startMonitor();
  }

  stopMonitor(): void {
    this.monitor.stopMonitor();
  }

  override logout() {
    this.monitor.stopMonitor();
    super.logout();
  }

  loadStorage(): boolean {
    const tokens = this.storage.get<TokenInterface>(StorageKeys.TOKENS);
    const user = this.storage.get<IUser>(StorageKeys.USER);

    if (!tokens || !user) {
      this.cleanStorage();
      return false;
    }

    this.user$.next(user);
    this.tokens$.next(tokens);

    this.initTokenBehavior();
    return true;
  }

  private async initTokenBehavior(): Promise<void> {
    const authRefresh = environment.token.autoRefresh.enabled;
    const refreshOnLoad = environment.token.refreshOnLoad;

    if (refreshOnLoad) {
      await this.refresh(true);
    }

    if (authRefresh) {
      this.monitor.startMonitor();
    }
  }

  async refresh(
    refreshUser: boolean = false,
    token: string = ''
  ): Promise<Data<TokenResponseInterface>> {
    const isRefreshing = await lastValueFrom(this.isRefreshing$.pipe(take(1)));

    if (isRefreshing) return null;

    this.isRefreshing$.next(true);

    const refreshToken = token ? token : this.tokens.refreshToken;

    const credentials = {
      grant_type: GranTypeEnum.refresh_token,
      refresh_token: refreshToken,
    } as CredentialsInterface;

    const headers = new HttpHeaders().set(
      'Authorization',
      `Basic ${this.clientCredentials}`
    );

    return lastValueFrom(
      this.http
        .post<ExtendedRecord<TokenResponseInterface>>(
          this.apiEndpoints.tokens,
          credentials,
          {
            observe: 'response',
            headers,
          }
        )
        .pipe(
          catchError(error => {
            this.onRefreshError(error);
            return throwError(() => error);
          }),
          tap(response => {
            if (response.ok) {
              this.terms = JSON.parse(
                response.headers.get('terms')
              ) as TermsResponseInterface;

              this.onRefreshSuccess(response.body.data);
            } else {
              this.onRefreshError(response as any);
            }
          })
        )
    );
  }

  get clientCredentials(): string {
    return btoa(`${environment.api.name}:${environment.api.secret}`);
  }

  private async onRefreshSuccess(
    tokens: TokenResponseInterface
  ): Promise<void> {
    const _tokens: Tokens = {
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token,
    };

    this.updateTokens(_tokens);

    const user: UserResponseInterface = tokens.user;

    this.updateUser(user);

    // if (refreshUser) await this.refreshUser();

    this.isRefreshing$.next(false);
    this.onAuthSuccess$.next(user);
  }

  private onRefreshError(error: HttpErrorResponse): void {
    console.log('Refresh error ->', error);

    const { status } = error;

    const errorHandler = {
      [401]: AuthErrors.TOKEN_EXPIRED,
      [430]: AuthErrors.TOKEN_EXPIRED,
      [404]: AuthErrors.TOKEN_EXPIRED,
      [0]: AuthErrors.OFFLINE,
      [429]: GeneralErrors.MANY_REQUESTS,
      DEFAULT: GeneralErrors.DEFAULT,
    };

    const errorType = errorHandler[status] ?? GeneralErrors.DEFAULT;

    this.setError(errorType);

    const logoutOnCode = [401, 404, 430, 0];

    if (logoutOnCode.includes(status)) {
      this.logout();
    }

    this.isRefreshing$.next(false);
  }
}
