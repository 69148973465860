import { Injectable, inject } from '@angular/core';
import { Observable, from, lastValueFrom, map, switchMap, tap } from 'rxjs';
import { CryptoService } from '../crypto/crypto.service';
declare var window: any;

// Default decimals for the assets and wallets
// TODO: Move to environment
export const DECIMALS_DEFAULT = {
  assetDefault: '1.2-2',
  assetSmall: '1.4-4',
  variationAssetDefault: '1.3-3',
  variationAssetSmall: '1.5-5',
  walletDefault: '1.2-2',
  walletTXDefault: '1.2-8',
};

export class APPConfig {
  production!: boolean;
  languages!: string[];
  defaultLang!: string;
  defaultTheme!: string;

  api!: {
    url: string;
    name: string;
    secret: string;
  };

  token!: {
    refreshOnLoad: boolean;
    autoRefresh: {
      enabled: boolean;
      time: string;
      unit: string;
    };
    reduceTime: {
      enabled: boolean;
      time: string;
      unit: string;
    };
  };

  cipher!: {
    secret: string;
    iv: string;
  };

  wallets: {
    showEuros: boolean;
    showPercent: boolean;
  };

  assets: {
    showEuros: boolean;
    showPercent: boolean;
  };

  rateLimiter: {
    enabled: boolean;
    seconds: number;
  };
}

export function initConfig(configService: ConfigService) {
  return () => configService.initConfig();
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configURI: string =
    '/assets/config/config.json?ts=' + new Date().getTime();
  private cryptoService = inject(CryptoService);
  private cryptoKey = '3nV1R0nM3NtVaRs2.0.2.4@!';
  private cryptoIV = 'A(sbXm1@2=9xAñ-A12[m!';
  private _config!: APPConfig;

  get config(): APPConfig {
    return this._config;
  }

  public initConfig(): Promise<APPConfig> {
    return lastValueFrom(
      from(
        fetch(this.configURI).then(function (response) {
          return response.text();
        })
      ).pipe(
        switchMap(this.decrypt.bind(this)),
        map(this.configMapper.bind(this)),
        tap((config: APPConfig) => {
          window.config = config;
        })
      )
    );
  }

  private decrypt(configEncoded: string): Observable<APPConfig> {
    const decrypt = this.cryptoService.decryptData(
      configEncoded,
      'AES-CBC',
      this.cryptoKey,
      this.cryptoIV
    );
    return from(decrypt).pipe(map(decodedConfig => JSON.parse(decodedConfig)));
  }

  private configMapper(config: any): APPConfig {
    return {
      ...this.castObjectVariables(config),
      languages: config.languages.split(','),
    };
  }

  private castObjectVariables(obj: any) {
    for (const key in obj) {
      const isBoolean =
        typeof obj[key] === 'string' &&
        ['true', 'false'].includes(obj[key]?.toLowerCase());
      const isObject = typeof obj[key] === 'object' && obj[key] !== null;
      if (isBoolean) {
        obj[key] = obj[key]?.toLowerCase() === 'true';
      } else if (isObject) {
        this.castObjectVariables(obj[key]);
      }
    }
    return obj;
  }
}
