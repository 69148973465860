import { Injectable } from '@angular/core';
import { WithdrawalQueryParamsInterface } from '@interfaces/user/wallet/withdrawal/withdrawal.query.params.interface';
import { WithdrawalResponseInterface } from '@interfaces/user/wallet/withdrawal/withdrawal.response.interface';
import { v4 as UUID } from 'uuid';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

export interface WithdrawalResume {
  amount: string;
  receiverAddress: string;
  assetId: string;
  assetName: string;
  assetImage: string;
  assetSymbol: string;
  feePercent: string;
  feeInAsset: string;
}

@Injectable({
  providedIn: 'root',
})
export class WithdrawalsService extends AbstractCrudService<
  WithdrawalResponseInterface,
  WithdrawalQueryParamsInterface
> {
  protected override feature = 'withdrawals';
  protected override endPoint = '/users/%s/wallets/%s/withdrawals/';

  makeWithdrawal(
    userId: string,
    walletId: string,
    amount: string,
    receiverAddress: string,
    assetId: string
  ): void {
    const withdrawalData = {
      amount,
      receiverAddress,
      assetId,
      externalReference: UUID(),
    } as WithdrawalResponseInterface;

    this.create(withdrawalData, [userId, walletId]);
  }
}
