import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { Big } from 'big.js';

export const assetsSorter = (
  asset1: AssetResponseInterface,
  asset2: AssetResponseInterface
) => {
  const a = new Big(asset1.marketData.marketCapRank);
  const b = new Big(asset2.marketData.marketCapRank);

  if (a.lt(b)) return -1;
  if (b.lt(a)) return 1;

  return 0;
};

export const walletsSorter = (
  wallet1: WalletResponseInterface,
  wallet2: WalletResponseInterface
) => {
  const { name: a } = wallet1.asset;
  const { name: b } = wallet2.asset;
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};
