import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthErrors,
  GeneralErrors,
} from '@app/shared/interfaces/auth/auth.error.enum';
import { getTokenExp, reduceExp } from '@app/shared/utils/token';
import { environment } from '@environments/environment';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { UsersService } from '../abstract-services/users/users.service';
import { StorageService } from '../localstorage/local-storage.service';

type User = UserResponseInterface;

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export enum StorageKeys {
  USER = 'user',
  TOKENS = 'tokens',
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  // Setup
  protected apiUrl: string = `${environment.api.url}`;
  protected apiEndpoints = {
    tokens: `${this.apiUrl}/tokens`,
  };

  // Async Store
  user$ = new BehaviorSubject<User | undefined>(undefined);
  tokens$ = new BehaviorSubject<Tokens | undefined>(undefined);

  // Events
  onAuthSuccess$ = new Subject<User>();
  onAuthError$ = new Subject<AuthErrors | GeneralErrors>();

  constructor(
    protected storage: StorageService,
    protected http: HttpClient,
    protected usersService: UsersService,
    protected router: Router
  ) {}

  // Getters
  get isLogged(): boolean {
    return Boolean(this.user?.id) && Boolean(this.tokens?.accessToken);
  }

  get isLogged$(): Observable<boolean> {
    return this.user$.pipe(
      map(user => Boolean(user?.id) && Boolean(this.tokens?.accessToken))
    );
  }

  get user(): User {
    return this.storage.get(StorageKeys.USER);
  }

  get tokens(): Tokens {
    return this.storage.get(StorageKeys.TOKENS);
  }

  // Methods
  async refreshUser(): Promise<void> {
    const user = await this.usersService.getUserByIdSync(this.user.id);
    this.updateUser(user);
  }

  // Storage
  cleanStorage(): void {
    this.storage.remove(StorageKeys.TOKENS);
    this.storage.remove(StorageKeys.USER);
    this.user$.next(undefined);
    this.tokens$.next(undefined);
  }

  updateUser(user: Partial<User>): void {
    const userModel = {
      ...this.user,
      ...user,
    };
    this.user$.next(userModel);
    this.storage.save('user', userModel);
  }

  updateTokens(tokens: Partial<Tokens>): void {
    const tokensModel = {
      ...this.tokens,
      ...tokens,
    };
    this.tokens$.next(tokensModel);
    this.storage.save('tokens', tokensModel);
  }

  getAccessTokenExp(): number {
    const expiration = getTokenExp(this.tokens.accessToken);
    const reduceTime = environment.token.reduceTime.enabled;
    if (!reduceTime) return expiration;
    return reduceExp(expiration);
  }

  logout() {
    this.cleanStorage();
  }

  setError(error: AuthErrors | GeneralErrors): void {
    this.onAuthError$.next(error);
  }
}
