import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { filter, from, Observable, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthRefreshService } from '../services/auth/auth-refresh.service';
import { isTSExpired } from '../utils/token';
import { isApiRequest } from './api-request.filter';

export function refreshInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthRefreshService);

  const isRefreshReq = req.url === `${environment.api.url}/auth/refresh-token`;

  const hasTokens = authService.tokens?.accessToken;

  const authorizedRequest = Boolean(req.headers.get('Authorization'));

  const apiRequest = isApiRequest(req.url);

  const skipRefresh =
    isRefreshReq || !hasTokens || authorizedRequest || !apiRequest;

  if (skipRefresh) return next(req);

  const expiration = authService.getAccessTokenExp();
  const isExpired = isTSExpired(expiration);

  if (!isExpired) return next(req);

  return from(authService.refresh()).pipe(
    switchMap(res => {
      if (res === null)
        return authService.isRefreshing$.pipe(
          filter(isRefreshing => !isRefreshing),
          take(1),
          switchMap(() => next(req))
        );
      return next(req);
    })
  );
}
