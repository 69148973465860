/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';

const SHA_512 = 'SHA-512';
const AES_CBC = 'AES-CBC';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private bufferAlgo: string = AES_CBC;
  private hashAlgo: string = SHA_512;
  private iv!: BufferSource;
  private key!: CryptoKey;

  async init(secret: string, iv: string): Promise<void> {
    if (typeof window === 'undefined') return;
    this.key = await this.keyBuffer(secret);
    this.iv = await this.ivBuffer(iv);
  }

  /*  async encryptData(data: string): Promise<string> {
    if (typeof window === 'undefined') return '';
    const encodedData = this.encodeBuffer(data);

    const aesEncryptParams: AesCbcParams = {
      name: this.bufferAlgo,
      iv: this.iv,
    };

    const encryptedData: ArrayBuffer = await window.crypto.subtle.encrypt(
      aesEncryptParams,
      this.key,
      encodedData
    );

    const encryptedBytes = Array.from(new Uint8Array(encryptedData));
    const b64Data = btoa(String.fromCharCode.apply(null, encryptedBytes));
    return b64Data;
  } */

  async decryptData(
    encryptedData: string,
    name: string = 'AES-CBC',
    key: string = '',
    iv: string = ''
  ): Promise<string> {
    const keyBuffer = key ? await this.keyBuffer(key) : this.key;
    const ivBuffer = iv ? await this.ivBuffer(iv) : this.iv;

    const clearText = await crypto.subtle.decrypt(
      {
        name,
        iv: ivBuffer,
      },
      keyBuffer,
      Buffer.from(encryptedData, 'base64')
    );

    return this.decodeBuffer(new Uint8Array(clearText));
  }

  private encodeBuffer(str: string): Uint8Array {
    return new TextEncoder().encode(str);
  }

  private decodeBuffer(str: ArrayBuffer): string {
    return new TextDecoder().decode(str);
  }

  private async keyBuffer(
    secret: string,
    name: string = 'AES-CBC'
  ): Promise<CryptoKey> {
    const key = (await this.generateHash(secret)).slice(0, 32);

    return window.crypto.subtle.importKey(
      'raw',
      key,
      { name },
      false,
      ['encrypt', 'decrypt']
    );
  }

  private async ivBuffer(iv: string): Promise<BufferSource> {
    const _iv = await this.generateHash(iv);
    return _iv.slice(0, 16);
  }

  private async generateHash(
    data: string,
    algo: string = SHA_512
  ): Promise<Uint8Array> {
    const hashBuffer = await window.crypto.subtle.digest(
      algo,
      this.encodeBuffer(data)
    );
    return new Uint8Array(hashBuffer);
  }
}
