export enum AuthErrors {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  OFFLINE = 'OFFLINE',
}

export enum AuthConfigErrors {
  REFRESH_TIME = 'La configuración de tiempo refresco del token en las variables de entorno es incorrecta',
  REDUCE_TIME = 'La configuración de reducción de tiempo del token en las variables de entorno es incorrecta',
}

export enum GeneralErrors {
  MANY_REQUESTS = 'MANY_REQUESTS',
  DEFAULT = 'DEFAULT',
}
