import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExtendedWallet } from '../wallets-manager/wallet.class';

export enum MenuListType {
  DASHBOARD = 'dashboard',
  WALLET = 'wallet',
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  #title$ = new BehaviorSubject<string>('');
  title$ = this.#title$.asObservable();

  #showMenuButton = new BehaviorSubject<boolean>(true);
  showMenuButton$ = this.#showMenuButton.asObservable();

  #menuListType = new BehaviorSubject<MenuListType>(MenuListType.DASHBOARD);
  menuListType$ = this.#menuListType.asObservable();

  #wallet = new BehaviorSubject<ExtendedWallet>(null);
  wallet$ = this.#wallet.asObservable();

  setTitle(title: string) {
    this.#title$.next(title);
  }

  setMenuButtonVisibility(show: boolean) {
    this.#showMenuButton.next(show);
  }

  setMenuListType(type: MenuListType) {
    this.#menuListType.next(type);
  }

  setWallet(wallet: ExtendedWallet) {
    this.#wallet.next(wallet);
  }
}
