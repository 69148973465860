import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIError } from '@app/shared/interfaces/abstract/error';
import { getElement } from '@app/shared/utils/abstract.rx.pipes';
import { ModelInterface } from '@interfaces/global/model.interface';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import {
  Observable,
  combineLatest,
  filter,
  first,
  lastValueFrom,
  map,
  tap,
} from 'rxjs';
import { ErrorsService } from '../abstract-services/error/errors.service';
import { AbstractHelper } from './abstract-helper.class';
import {
  AbstractService,
  HttpUpdateMethods,
  IHeaders,
  ObservableParams,
} from './abstract.service';

export enum CRUDEvents {
  ALL = 'all',
  ERROR = 'error',
  CREATE = 'create',
  READ = 'read',
  READALL = 'readAll',
  UPDATE = 'update',
  DELETE = 'delete',
}

@Injectable()
export class AbstractCrudService<
  IModel extends ModelInterface,
  IOptions extends QueryParamsInterface,
> extends AbstractService<IModel, IOptions> {
  protected feature: string = '';
  protected override endPoint: string = '';
  protected override errorsSection: string = '';

  constructor(
    protected override http: HttpClient,
    protected override errorsService: ErrorsService
  ) {
    super(http, errorsService);
    this.errorsSection = this.sections.error;
  }

  private get serviceName() {
    return this.feature;
  }

  get sections() {
    return {
      error: `errors`,
      all: this.serviceName,
      create: `${this.serviceName}.${CRUDEvents.CREATE}`,
      read: `${this.serviceName}.${CRUDEvents.READ}`,
      readAll: `${this.serviceName}.${CRUDEvents.READALL}`,
      update: `${this.serviceName}.${CRUDEvents.UPDATE}`,
      delete: `${this.serviceName}.${CRUDEvents.DELETE}`,
    };
  }

  onLoading$(event: CRUDEvents = CRUDEvents.READALL): Observable<boolean> {
    if (event === CRUDEvents.ALL)
      return combineLatest([
        this.onLoading$(CRUDEvents.CREATE),
        this.onLoading$(CRUDEvents.READ),
        this.onLoading$(CRUDEvents.READALL),
        this.onLoading$(CRUDEvents.UPDATE),
        this.onLoading$(CRUDEvents.DELETE),
      ]).pipe(map(([c, r, ra, u, d]) => !!c || !!r || !!ra || !!u || !!d));

    return this.getObservableUpdating(this.sections[event]);
  }

  onCreate$(
    obParams: ObservableParams = {
      getElement: true,
      cleanSection: true,
    }
  ): Observable<IModel> {
    if (obParams.cleanSection) this.cleanSection(this.sections.create);
    return this._get(this.sections.create, obParams);
  }

  onRead$(
    obParams: ObservableParams = {
      arrayEmpty: true,
      cleanSection: true,
      getElement: true,
    }
  ): Observable<IModel> {
    if (obParams.cleanSection) this.cleanSection(this.sections.read);
    return this._get(this.sections.read, obParams);
  }

  onReadAll$(
    obParams: ObservableParams = {
      arrayEmpty: true,
      cleanSection: false,
    }
  ): Observable<IModel[]> {
    if (obParams.cleanSection) this.cleanSection(this.sections.readAll);
    return this._get(this.sections.readAll, obParams);
  }

  onUpdate$(
    obParams: ObservableParams = {
      getElement: true,
      cleanSection: true,
    }
  ): Observable<IModel> {
    if (obParams.cleanSection) this.cleanSection(this.sections.update);
    return this._get(this.sections.update, obParams);
  }

  onDelete$(
    obParams: ObservableParams = {
      getElement: true,
      cleanSection: true,
    }
  ): Observable<IModel> {
    return this._get(this.sections.delete, obParams);
  }

  onError$(event: CRUDEvents = CRUDEvents.ERROR): Observable<APIError> {
    const section =
      event === CRUDEvents.ERROR
        ? `${this.sections.error}.${this.serviceName}`
        : `${this.sections.error}.${this.serviceName}.${event}`;

    return this.errorsService.getObservable(section).pipe(
      getElement(),
      tap(error => this.errorsService.remove(error.id)),
      map(error => error.payload.error)
    );
  }

  private _get<T>(
    section: string,
    obParams: ObservableParams = {}
  ): Observable<T> {
    return this.getObservable(section, obParams) as Observable<T>;
  }

  getHeaders(event: CRUDEvents): Observable<IHeaders> {
    const section = `${this.serviceName}.${event}`;
    return super.getObservableHeaders(section).pipe(filter(t => !!t));
  }

  getLoading$(section: string): Observable<boolean> {
    return super.getObservableUpdating(section);
  }

  async getHeadersSync(event: CRUDEvents): Promise<IHeaders> {
    return lastValueFrom(this.getHeaders(event).pipe(first()));
  }

  create(
    model: Partial<IModel>,
    urlParams: string[] = [],
    url: string = null,
    addToSection: string[] = []
  ) {
    this.checkSections();
    addToSection = addToSection.map(section => this.sections[section]);
    return super._create(
      model as IModel,
      [this.sections.create, ...addToSection],
      urlParams,
      url
    );
  }

  getAll(
    externalOptions: IOptions = {} as IOptions,
    urlParams: string[] = [],
    url: string = null,
    replace: boolean = true
  ): AbstractHelper<IModel> {
    this.checkSections();
    return super._getAll(
      externalOptions,
      this.sections.readAll,
      urlParams,
      url,
      replace
    );
  }

  getOne(
    hash: string,
    urlParams: string[] = [],
    url: string = null,
    push: boolean = true
  ): AbstractHelper<IModel> {
    this.checkSections();
    return super._getOne(hash, this.sections.read, urlParams, url, push);
  }

  update(
    method: HttpUpdateMethods,
    hash: string,
    model: Partial<IModel>,
    urlParams: string[] = [],
    url: string = null,
    token: string = ''
  ): AbstractHelper<IModel> {
    this.checkSections();

    return super._update(
      method,
      hash,
      model as IModel,
      this.sections.update,
      urlParams,
      url,
      token
    );
  }

  delete(
    hash: string,
    urlParams: string[] = [],
    url: string = null
  ): AbstractHelper<IModel> {
    this.checkSections();
    return super._delete(hash, this.sections.delete, urlParams, url);
  }

  private checkSections(): void {
    Object.keys(this.sections).forEach(key => {
      this.checkSection(this.sections[key]);
    });
  }

  cleanEvent(event: CRUDEvents | string): void {
    const section = `${this.serviceName}.${event}`;
    this.cleanSection(section);
  }
}
