import { Injectable } from '@angular/core';
import { AbstractError } from '@app/shared/interfaces/abstract/error';
import { SubjectStore } from '@app/shared/interfaces/abstract/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { v7 as UUID } from 'uuid';

type AbstractErrorOrNull = AbstractError[] | null;

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  private dataStore: SubjectStore<AbstractErrorOrNull>;
  private behaviorSubjects: SubjectStore<BehaviorSubject<AbstractErrorOrNull>>;
  private observable: SubjectStore<Observable<AbstractErrorOrNull>>;

  constructor() {
    this.dataStore = {
      standard: [] as AbstractErrorOrNull,
    };
    this.behaviorSubjects = {
      standard: new BehaviorSubject(
        null
      ) as BehaviorSubject<AbstractErrorOrNull | null>,
    };
    this.observable = {
      standard: this.behaviorSubjects.standard.asObservable(),
    };
  }

  private checkSection(section = 'standard', force = false) {
    if (force || !this.dataStore[section]) {
      this.dataStore[section] = [] as AbstractErrorOrNull;
      this.behaviorSubjects[section] = new BehaviorSubject(
        null
      ) as BehaviorSubject<AbstractErrorOrNull>;
      this.observable[section] = this.behaviorSubjects[section].asObservable();

      const subSections = section.replace(/.([^.])*$/gi, '');
      if (subSections !== section) {
        this.checkSection(subSections);
      }
    }
    return true;
  }

  private checkSubsections(section: string = ''): string {
    if (this.dataStore[section]) {
      return section;
    }

    // if exists a subsection
    const subSections = section.replace(/\.([^.])*$/gi, '');
    if (subSections !== section) {
      return this.checkSubsections(subSections);
    }

    return 'standard';
  }

  getObservable(section = 'standard'): Observable<AbstractErrorOrNull> {
    this.checkSection(section);
    return this.observable[section];
  }

  cleanSection(section = 'standard') {
    this.checkSection(section);
    this.dataStore[section] = [] as AbstractErrorOrNull;
    this.behaviorSubjects[section].next(
      Object.assign({}, this.dataStore)[section]
    );
  }

  create(section = 'standard', error: AbstractError, realSection: string = '') {
    realSection = realSection || section;
    error.id = error.id || UUID();

    error.section = realSection;

    section = this.checkSubsections(section);

    if (!this.dataStore[section]) {
      section = 'standard';
    }

    this.dataStore[section]?.push(error);
    this.behaviorSubjects[section].next(
      Object.assign({}, this.dataStore)[section]
    );

    const subSections = section.replace(/\.([^.])*$/gi, '');
    if (subSections !== section) {
      this.create(subSections, error, realSection);
    }
  }

  update(error: AbstractError, key = 'id') {
    Object.keys(this.dataStore).forEach(section => {
      this.dataStore[section]?.forEach((t, i) => {
        if (t[key] === error[key]) {
          this.dataStore[section]![i] = error;
        }
      });

      this.behaviorSubjects[section].next(
        Object.assign({}, this.dataStore)[section]
      );
    });
  }

  remove(id: string, key = 'id') {
    Object.keys(this.dataStore).forEach(section => {
      this.dataStore[section]?.forEach((t, i) => {
        if (t[key] === id) {
          this.dataStore[section]?.splice(i, 1);
        }
      });

      this.behaviorSubjects[section].next(
        Object.assign({}, this.dataStore)[section]
      );
    });
  }
}
