import { Injectable } from '@angular/core';
import { BasicObservable } from '@app/shared/interfaces/abstract/basic';
import {
  BehaviorSubject,
  Observable,
  filter,
  first,
  of,
  switchMap,
} from 'rxjs';

export interface BasicObservableParams {
  arrayEmpty?: boolean;
  onlyFirst?: boolean;
  null?: boolean;
  getElement?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BasicObservableService {
  private dataStore: {
    standard: BasicObservable[];
  };
  private subjects: {
    standard: BehaviorSubject<BasicObservable[]>;
  };

  constructor() {
    this.dataStore = {
      standard: [] as BasicObservable[],
    };
    this.subjects = {
      standard: new BehaviorSubject([]) as BehaviorSubject<BasicObservable[]>,
    };
  }

  private checkSection(section = 'standard', force = false) {
    if (force || !this.dataStore[section]) {
      this.dataStore[section] = [] as BasicObservable[];
      this.subjects[section] = new BehaviorSubject(null) as BehaviorSubject<
        BasicObservable[]
      >;

      const subSections = section.replace(/\.([^.])*$/gi, '');
      if (subSections !== section) {
        this.checkSection(subSections);
      }
    }
    return true;
  }

  public getObservable<T>(
    section = 'standard',
    params: BasicObservableParams = {}
  ): Observable<T> {
    this.checkSection(section);

    const observable = (
      this.subjects[section] as unknown as Observable<T>
    ).pipe(
      filter(data => {
        if (!data) {
          return false;
        }

        // not nulls
        if (!params?.null && (data === null || data === undefined)) {
          return false;
        }

        // array -> not empty (length > 0)
        if (params?.arrayEmpty && Array.isArray(data) && data.length === 0) {
          return false;
        }

        return true;
      }),
      switchMap(data => {
        if (params?.getElement && Array.isArray(data)) {
          return of((data as T[]).at(-1) as T);
        }

        return of(data);
      })
    );

    return params?.onlyFirst ? observable.pipe(first()) : observable;
  }

  cleanSection(section = 'standard'): void {
    this.checkSection(section);
    this.dataStore[section] = [] as BasicObservable[];
    this.subjects[section].next(Object.assign({}, this.dataStore)[section]);
  }

  create(basicObservable: BasicObservable, section = 'standard'): void {
    this.checkSection(section);
    this.dataStore[section].push(basicObservable);
    this.subjects[section].next(Object.assign({}, this.dataStore)[section]);

    const subSections = section.replace(/\.([^.])*$/gi, '');
    if (subSections !== section) {
      this.create(basicObservable, subSections);
    }
  }

  update(
    basicObservable: BasicObservable,
    addToSection: string = null,
    _key: string = 'id'
  ): void {
    // Tricky: Actualización del store simplificado.
    if (!this.dataStore[addToSection]) {
      this.create(basicObservable, addToSection);
      return;
    }

    this.dataStore[addToSection][0] = basicObservable;

    this.subjects[addToSection].next(
      Object.assign({}, this.dataStore)[addToSection]
    );

    /* 
    let added = false;
    Object.keys(this.dataStore).forEach(section => {
      let dataInSection = false;
      this.dataStore[section].forEach((t, i) => {
        if (t[key] === basicObservable[key]) {
          if (addToSection === section) {
            added = true;
          }
          dataInSection = true;

          this.dataStore[section][i] = basicObservable;
        }
      });

      if (dataInSection) {
        this.subjects[section].next(
          Object.assign({}, this.dataStore)[section]
        );
      }
    });
    console.log({
      added,
      add
    })
    if (!added && addToSection !== null) {
      this.create(basicObservable, addToSection);
    } 
    */
  }

  remove(id: string, key = 'id'): void {
    Object.keys(this.dataStore).forEach(section => {
      this.dataStore[section].forEach((t, i) => {
        if (t[key] === id) {
          this.dataStore[section].splice(i, 1);
        }
      });

      this.subjects[section].next(Object.assign({}, this.dataStore)[section]);
    });
  }
}
