export enum AbstractEvent {
  BACKEND_OFFLINE = 'BACKEND_OFFLINE',
  GLOBAL_MESSAGE = 'GLOBAL_MESSAGE',
  SET_THEME = 'SET_THEME',
  ROUTE = 'ROUTE',
  HEADER_TITLE = 'HEADER_TITLE',
}

export interface GlobalMessage {
  title: string;
  message: string;
  life: number;
  severity: string;
}
