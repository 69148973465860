import { Injectable } from '@angular/core';
import { AlertQueryParamsInterface } from '@interfaces/alert/alert.query.params.interface';
import { AlertResponseInterface } from '@interfaces/alert/alert.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsService extends AbstractCrudService<
  AlertResponseInterface,
  AlertQueryParamsInterface
> {
  protected override feature = 'alerts';
  protected override endPoint = '/alerts/';
}
