import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { ExtendedWallet } from '../wallet.class';

export function mergeAssets(
  wallets: WalletResponseInterface[],
  assets: AssetResponseInterface[]
): Partial<ExtendedWallet[]> {
  return wallets.map(wallet => ({
    ...wallet,
    asset: assets.find(asset => asset.symbol === wallet.asset.symbol),
  }));
}
