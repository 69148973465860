<ion-menu
  #menu
  side="end"
  contentId="main-content"
  type="overlay"
  [swipeGesture]="false"
>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ 'admin.menu.title' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    @if (walletsManager.onLoading$ | async) {
      @for (item of [1, 2, 3, 4]; track $index) {
        <ion-skeleton-text
          animated
          style="height: 48px; margin-bottom: 10px"
        ></ion-skeleton-text>
      }
    } @else {
      @for (
        item of menuListType$
          | async
          | menuList: (headerService.wallet$ | async);
        track $index
      ) {
        <ion-item (click)="navigateTo(item.route)">
          <ion-label>{{
            item.label | translate: item.translateParams
          }}</ion-label>
        </ion-item>
      }
    }
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-button (click)="exit()" class="ion-padding" fill="none">
        <i class="fal fa-sign-out fa-2x mr-10"></i>
        {{ 'admin.menu.exit' | translate }}
      </ion-button>
    </ion-toolbar>
  </ion-footer>
</ion-menu>
