import { inject, Injectable } from '@angular/core';
import { RateLimiter } from '@app/shared/utils/rate-limiter.class';
import { environment } from '@environments/environment';
import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  take,
} from 'rxjs';
import { AssetsService } from '../abstract-services/assets/assets.service';
import { CRUDEvents } from '../abstract/abstract-crud.service';
import { assetsSorter } from '../wallets-manager/utils/sorters';
import { EAsset, ExtendedAsset } from './asset.class';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class AssetsManagerService {
  #assetsService = inject(AssetsService);
  initialized = false;
  private loading = new BehaviorSubject<boolean>(true);
  onLoading$ = this.loading.asObservable();

  #assets: ExtendedAsset[] = [];
  private assets = new BehaviorSubject<ExtendedAsset[]>([]);
  onAssets$ = this.assets.asObservable().pipe(filter(w => !!w.length));

  rateLimiter: RateLimiter = new RateLimiter(environment.rateLimiter.seconds);

  get _assets() {
    return this.#assets;
  }

  // Refresh global
  async refresh(): Promise<void> {
    if (environment.rateLimiter.enabled && !this.rateLimiter.isAllowed())
      return;

    const isLoading = await lastValueFrom(this.onLoading$.pipe(take(1)));
    if (isLoading && this.initialized) return;

    this.getAssets();
  }

  private setAssets(assets: AssetResponseInterface[]): void {
    assets.sort(assetsSorter);

    const extendedAssets = assets.map(asset => new EAsset(asset).create());

    this.#assets = extendedAssets;
    this.assets.next(this.#assets);

    this.loading.next(false);
  }

  private getAssets(): void {
    this.loading.next(true);

    this.#assetsService.cleanEvent(CRUDEvents.READALL);
    this.#assetsService.getAll({ limit: 0 });

    this.#assetsService
      .onReadAll$({
        arrayEmpty: false,
        onlyFirst: true,
      })
      .subscribe(this.setAssets.bind(this));
  }

  findAssetById(id: string): ExtendedAsset | undefined {
    return this.#assets.find(asset => asset.id === id);
  }

  onAssetById$(assetId: string): Observable<ExtendedAsset> {
    return this.onLoading$.pipe(
      filter(t => !t),
      switchMap(() =>
        this.onAssets$.pipe(
          map(wallets => wallets.find(wallet => wallet.id === assetId)),
          filter(wallet => wallet !== null),
          distinctUntilChanged()
        )
      )
    );
  }
}
