import { BasicObservable } from '@shared/interfaces/abstract/basic';
import { filter, map, Observable } from 'rxjs';

function getPayload() {
  return function <T>(source: Observable<BasicObservable[]>): Observable<T> {
    return new Observable(subscriber => {
      source
        .pipe(
          filter((data: BasicObservable[]) => !!data && !!data.length),
          map((data: BasicObservable[]) => data[data.length - 1].payload)
        )
        .subscribe({
          next(payload) {
            subscriber.next(payload);
          },
        });
    });
  };
}

function getElement<T>() {
  return function (source: Observable<T[] | T>): Observable<T> {
    return new Observable(subscriber => {
      source
        .pipe(
          filter((data: any) => !!data && !!data.length),
          map((data: T[] | T) => {
            if (Array.isArray(data)) {
              return data[(data as T[]).length - 1];
            }

            return data;
          })
        )
        .subscribe({
          next(data) {
            subscriber.next(data);
          },
        });
    });
  };
}

export { getElement, getPayload };
