import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FadeIn } from '@app/shared/animations/fade-in.animation';
import { IonicComponentsModule } from '@app/shared/modules/ionic-components/ionic-components.module';
import { HeaderService } from '@app/shared/services/header/header.service';
import { NavController } from '@ionic/angular/standalone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, map, startWith } from 'rxjs';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, HeaderMenuComponent, IonicComponentsModule],
  templateUrl: './header.component.html',
  animations: [FadeIn(200, false)],
})
export class HeaderComponent {
  #router = inject(Router);
  #headerService = inject(HeaderService);
  #navController = inject(NavController);

  mainRoute: string = '/admin';

  canGoBack$ = this.#router.events.pipe(
    startWith(this.#router.url !== this.mainRoute),
    filter((event: any) => event instanceof NavigationEnd),
    map(event => event.urlAfterRedirects !== this.mainRoute)
  );

  title$ = this.#headerService.title$;
  showMenuButton$ = this.#headerService.showMenuButton$;

  logoUrl = '/assets/img/png/onyze.png';

  goBack(): void {
    const comingFromOperation =
      this.#router.url.endsWith('/operation/buy') ||
      this.#router.url.endsWith('/operation/sell');

    if (comingFromOperation) {
      this.redirectToWalletAfterOperation();
      return;
    }

    const comingFromWallet = /^\/admin\/wallet\/[A-Za-z0-9]+$/.test(
      this.#router.url
    );

    if (comingFromWallet) {
      this.redirectToDashboardAfterWallet();
      return;
    }

    const comingFromWithdraw = /^\/admin\/wallet\/[A-Za-z0-9]+\/withdraw$/.test(
      this.#router.url
    );

    if (comingFromWithdraw) {
      this.redirectToWalletAfterWithdraw();
      return;
    }

    this.#navController.back();
  }

  redirectToWalletAfterOperation(): void {
    const nextUrl = this.#router.url
      .replace('/operation/buy', '')
      .replace('/operation/sell', '');

    this.#navController.navigateBack([nextUrl], { replaceUrl: true });
  }

  redirectToDashboardAfterWallet(): void {
    this.#navController.navigateBack(['/admin'], { replaceUrl: true });
  }

  redirectToWalletAfterWithdraw(): void {
    const nextUrl = this.#router.url.replace('/withdraw', '');
    this.#navController.navigateBack([nextUrl], { replaceUrl: true });
  }
}
