import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { IonicComponentsModule } from '@app/shared/modules/ionic-components/ionic-components.module';
import { TermsManagerService } from '@app/shared/services/terms-manager/terms-manager.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { ModalController } from '@ionic/angular/standalone';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs';
import { CardBoxComponent } from '../card-box/card-box.component';
import { LoadingBoxComponent } from '../loading-box/loading-box.component';

@UntilDestroy()
@Component({
  selector: 'app-terms-modal',
  standalone: true,
  imports: [
    CommonModule,
    IonicComponentsModule,
    TranslateModule,
    DirectivesModule,
    LoadingBoxComponent,
    CardBoxComponent,
  ],
  templateUrl: './terms-modal.component.html',
})
export class TermsModalComponent implements OnInit {
  #termsService = inject(TermsManagerService);
  #toastService = inject(ToastService);
  #translate = inject(TranslateService);
  #modalCtrl = inject(ModalController);

  termsId: string;

  onTerms$ = this.#termsService.onTerms$;
  onTermsError$ = this.#termsService.onTermsError$;
  onTermsUpdate$ = this.#termsService.onTermsUpdate$;
  onTermsLoading$ = this.#termsService.onTermsLoading$.pipe(startWith(true));

  ngOnInit(): void {
    this.initListeners();
    this.#termsService.getTerms();
  }

  private initListeners(): void {
    this.onTermsError$
      .pipe(untilDestroyed(this))
      .subscribe(this.onTermsError.bind(this));

    this.onTermsUpdate$
      .pipe(untilDestroyed(this))
      .subscribe(this.onTermsUpdate.bind(this));
  }

  private onTermsError(): void {
    const message = this.#translate.instant('errors.general.default');
    this.#toastService.createToast(message, 2000, 'danger');
  }

  private onTermsUpdate(): void {
    this.#modalCtrl.dismiss();
  }

  updateTerms() {
    this.#termsService.updateTerms();
  }
}
