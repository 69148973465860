import { Injectable } from '@angular/core';
import { AssetQueryParamsInterface } from '@interfaces/asset/asset.query.params.interface';
import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { AssetRateTypes } from '@interfaces/asset/enums/asset-rate-types.enum';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsService extends AbstractCrudService<
  AssetResponseInterface,
  AssetQueryParamsInterface
> {
  public ASSET_RATE_SECTION = 'asset-rate';
  public ASSET_PURCHASE_CONFIG_SECTION = 'purchase-config';

  protected override feature = 'assets';
  protected override endPoint = '/assets/';

  public getRate(hash: string, operation: AssetRateTypes = null): void {
    const baseEndpointUrl = `${this.baseUrl}${this.endPoint}${hash}/rate`;

    const endpointUrl = operation
      ? `${baseEndpointUrl}?type=${operation}`
      : baseEndpointUrl;

    this.cleanRate();

    this.queryGetOne(this.ASSET_RATE_SECTION, endpointUrl);
  }

  public getAssetPurchaseConfig(
    assetId: string,
    section = this.ASSET_PURCHASE_CONFIG_SECTION
  ): void {
    const endpointUrl = `${this.baseUrl}/configurations/assets/${assetId}/purchases`;
    this.queryGetAll({}, section, endpointUrl);
  }

  cleanRate(): void {
    this.cleanSection(this.ASSET_RATE_SECTION);
  }
}
