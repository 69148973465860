import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FadeIn } from '@app/shared/animations/fade-in.animation';
import { IonicComponentsModule } from '@app/shared/modules/ionic-components/ionic-components.module';

export enum SpinnerTypes {
  BUBBLES = 'bubbles',
  CIRCLES = 'circles',
  CIRCULAR = 'circular',
  CRESCENT = 'crescent',
  DOTS = 'dots',
  LINES = 'lines',
  LINES_SMALL = 'lines-small',
  LINES_SHARP = 'lines-sharp',
  LINES_SHARP_SMALL = 'lines-sharp-small',
}

@Component({
  selector: 'app-loading-box',
  standalone: true,
  imports: [CommonModule, IonicComponentsModule],
  template: `<div class="loading-box" [@fadeIn]>
    <ion-spinner [name]="loadingStyle()" color="primary"></ion-spinner>
  </div>`,
  animations: [FadeIn(200, false)],
})
export class LoadingBoxComponent {
  loadingStyle = input<SpinnerTypes>(SpinnerTypes.DOTS);
}
