import { inject, Injectable } from '@angular/core';
import { RateLimiter } from '@app/shared/utils/rate-limiter.class';
import { environment } from '@environments/environment';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Big } from 'big.js';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  take,
} from 'rxjs';
import { WalletsService } from '../abstract-services/wallets/wallets.service';
import { CRUDEvents } from '../abstract/abstract-crud.service';
import { AssetsManagerService } from '../assets-manager/assets-manager.service';
import { AuthRefreshService } from '../auth/auth-refresh.service';
import { mergeAssets } from './utils/mergeAssets';
import { walletsSorter } from './utils/sorters';
import { calculateTotalBalance, prepareWalletList } from './utils/wallets';
import { EWallet, ExtendedWallet } from './wallet.class';

@UntilDestroy({ checkProperties: true, arrayName: 'listeners' })
@Injectable({
  providedIn: 'root',
})
export class WalletsManagerService {
  #authService = inject(AuthRefreshService);
  #walletsService = inject(WalletsService);
  #assetsManagerService = inject(AssetsManagerService);

  initialized = false;

  private loading = new BehaviorSubject<boolean>(true);
  onLoading$ = this.loading.asObservable();

  #wallets: ExtendedWallet[] = [];
  private wallets = new BehaviorSubject<ExtendedWallet[]>([]);
  onWallets$ = this.wallets.asObservable().pipe(filter(w => !!w.length));

  #totalBalance: Big;
  private totalBalance = new BehaviorSubject<number>(0);
  onTotalBalance$ = this.totalBalance.asObservable();

  onCreate$ = this.#walletsService
    .onCreate$()
    .pipe(map(this.addWallet.bind(this)));

  rateLimiter: RateLimiter = new RateLimiter(environment.rateLimiter.seconds);

  constructor() {
    this.#authService.onAuthSuccess$
      .pipe(take(1))
      .subscribe(this.refresh.bind(this));
  }

  async refresh() {
    if (environment.rateLimiter.enabled && !this.rateLimiter.isAllowed())
      return;

    const isLoading = await lastValueFrom(this.onLoading$.pipe(take(1)));
    if (isLoading && this.initialized) return;
    this.loading.next(true);
    this.#assetsManagerService.refresh();
    this.getWallets();
  }

  get _wallets() {
    return this.#wallets;
  }

  get _totalBalance() {
    return this.#totalBalance;
  }

  get _afterLoading$(): Observable<boolean> {
    return this.onLoading$.pipe(
      filter(t => !t),
      take(1)
    );
  }

  private getWallets(): void {
    this.loading.next(true);
    this.#walletsService.cleanEvent(CRUDEvents.READALL);
    this.#walletsService.getAll({ limit: 0 }, [this.#authService.user.id]);

    this.#walletsService
      .onReadAll$({
        arrayEmpty: false,
        onlyFirst: true,
      })
      .subscribe(this.setWallets.bind(this));
  }

  private async setWallets(wallets: WalletResponseInterface[]): Promise<void> {
    // Wait for assets to be loaded
    await lastValueFrom(
      this.#assetsManagerService.onLoading$.pipe(
        filter(l => !l),
        take(1)
      )
    );

    wallets.sort(walletsSorter);

    const assets = this.#assetsManagerService._assets;

    // prettier-ignore
    const extendedWallets = prepareWalletList(
      mergeAssets(wallets, assets), 
      assets
    )

    .map(wallet => new EWallet(wallet).create());

    this.#wallets = extendedWallets;
    this.wallets.next(this.#wallets);

    this.#totalBalance = calculateTotalBalance(extendedWallets);
    this.totalBalance.next(this.#totalBalance.toNumber());

    this.loading.next(false);
    this.initialized = true;
  }

  findWalletByAssetId(assetId: string): ExtendedWallet | null {
    return this.#wallets.find(
      (wallet: ExtendedWallet) => wallet.asset?.id === assetId
    );
  }

  findWalletById(walletId: string): ExtendedWallet | null {
    return this.#wallets?.find(wallet => wallet.id === walletId);
  }

  createWallet(assetId: string): void {
    this.#walletsService.create({ assetId }, [this.#authService.user.id]);
  }

  onWalletById$(walletId: string): Observable<ExtendedWallet> {
    return this.onLoading$.pipe(
      filter(t => !t),
      switchMap(() =>
        this.onWallets$.pipe(
          map(wallets => wallets.find(wallet => wallet.id === walletId)),
          filter(wallet => wallet !== null),
          distinctUntilChanged()
        )
      )
    );
  }

  private addWallet(wallet: WalletResponseInterface): ExtendedWallet {
    const fakeWalletIdx = this.#wallets.findIndex(
      wallet => wallet.asset.id === wallet.asset.id
    );

    const asset = this.#assetsManagerService.findAssetById(wallet.asset.id);
    const extendedWallet = new EWallet({ ...wallet, asset: asset }).create();

    if (fakeWalletIdx === -1) {
      this.#wallets.push(extendedWallet);
    } else {
      this.#wallets[fakeWalletIdx] = extendedWallet;
    }

    this.wallets.next(this.#wallets);
    return extendedWallet;
  }
}
