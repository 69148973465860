import { ModelInterface } from '@interfaces/global/model.interface';

export enum TimeUnit {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export function secondsToUnit(time: number, unit: TimeUnit): number {
  const unitHandler = {
    [TimeUnit.MINUTES]: time * 60,
    [TimeUnit.HOURS]: time * 3600,
    [TimeUnit.DAYS]: time * 86400,
  };

  return unitHandler[unit] ?? 0;
}

export function normalizeDate(
  date: Date | string,
  isDetailed: boolean = false
): string {
  const today = new Date();
  date = new Date(date);

  const startOfToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const isToday =
    date >= startOfToday && date < new Date(startOfToday.getTime() + 86400000); // 86400000ms = 1 día

  if (isToday) {
    const day = 'admin.transactions.today';
    const hour = date.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return isDetailed ? `${day}, ${hour}` : day;
  }

  const options: Intl.DateTimeFormatOptions = isDetailed
    ? { day: '2-digit', month: '2-digit', year: 'numeric' }
    : { day: '2-digit', month: 'long' };

  return date.toLocaleDateString('es-ES', options);
}

export const sortByCreatedAt = (a: ModelInterface, b: ModelInterface) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
