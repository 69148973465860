import { Injectable } from '@angular/core';
import { WalletQueryParamsInterface } from '@interfaces/user/wallet/wallet.query.params.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class WalletsService extends AbstractCrudService<
  WalletResponseInterface,
  WalletQueryParamsInterface
> {
  protected override feature = 'wallets';
  protected override endPoint = '/users/%s/wallets/';
}
