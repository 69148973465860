import { NgModule } from '@angular/core';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { InputValidatorDirective } from './input-validate.directive';
import { VarDirective } from './ng-var.directive';

const directives = [
  InputValidatorDirective,
  VarDirective,
  CopyToClipboardDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
