import { CommonModule, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, LOCALE_ID } from '@angular/core';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import {
  add,
  caretBack,
  chevronBackOutline,
  menuOutline,
  trashOutline,
} from 'ionicons/icons';
import { HeaderComponent } from './shared/components/header/header.component';
import { AbstractEvent } from './shared/interfaces/abstract/events';
import { IonicComponentsModule } from './shared/modules/ionic-components/ionic-components.module';
import { AbstractStore } from './shared/services/abstract/abstract-store.service';
import { AuthRefreshService } from './shared/services/auth/auth-refresh.service';
import { CryptoService } from './shared/services/crypto/crypto.service';
import { TermsManagerService } from './shared/services/terms-manager/terms-manager.service';
import { ThemeService } from './shared/services/theme/theme.service';
import { ToastService } from './shared/services/toast/toast.service';
import { getDefaultTheme } from './shared/utils/theme';

registerLocaleData(es);

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, HeaderComponent, IonicComponentsModule],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }],
  template: `
    <ion-app>
      @if (authService.isLogged$ | async) {
        <app-header></app-header>
      } @else {
        <ion-router-outlet></ion-router-outlet>
      }
    </ion-app>
  `,
})
export class AppComponent {
  constructor(
    public authService: AuthRefreshService,
    private store: AbstractStore,
    private translate: TranslateService,
    private cryptoService: CryptoService,
    private themeService: ThemeService,
    private toastService: ToastService,
    private termsService: TermsManagerService,
    private readonly platform: Platform
  ) {
    this.initIcons();
  }

  async ngOnInit(): Promise<void> {
    await this.initCipher();
    this.authService.loadStorage();
    this.initTranslate();
    this.initGlobalErrors();
    this.initTheme();
    await this.platform.ready();
    this.checkTerms();
  }

  private checkTerms(): void {
    this.authService.onAuthSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.termsService.showTerms());
  }

  private initIcons(): void {
    addIcons({
      caretBack,
      chevronBackOutline,
      menuOutline,
      add,
      trashOutline,
    });
  }

  private initTheme(): void {
    const theme = getDefaultTheme();
    this.themeService.setTheme(theme);
  }

  private initTranslate(): void {
    this.translate.setDefaultLang(environment.defaultLang);
    this.translate.use(environment.defaultLang);
  }

  private async initCipher(): Promise<void> {
    this.cryptoService.init(environment.cipher.secret, environment.cipher.iv);
  }

  private initGlobalErrors(): void {
    this.store
      .getAsEvent(AbstractEvent.BACKEND_OFFLINE)
      .pipe(untilDestroyed(this))
      .subscribe(this.onBackendOffline.bind(this));
  }

  private onBackendOffline(): void {
    console.error('[APP] Backend Offline');
  }
}
