import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthRefreshService } from '../services/auth/auth-refresh.service';
import { RedirectRoutes } from './redirect.routes.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthRefreshService,
    private router: Router
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): MaybeAsync<GuardResult> {
    if (this.authService.isLogged) return true;
    const nextUrlTree = this.router.parseUrl(RedirectRoutes.ERROR);
    return new RedirectCommand(nextUrlTree);
  }
}
