import { Injectable } from '@angular/core';
import { WalletTotalsQueryParamsInterface } from '@interfaces/stats/wallet-totals.query.params.interface';
import { WalletTotalsResponseInterface } from '@interfaces/stats/wallet-totals.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class WalletTotalsService extends AbstractCrudService<
  WalletTotalsResponseInterface,
  WalletTotalsQueryParamsInterface
> {
  protected override feature = 'wallet-totals';
  protected override endPoint = `/stats/totals/`;
}
