import { AssetResponseInterface } from '@interfaces/asset/asset.response.interface';
import { Big } from 'big.js';
import { ExtendedWallet } from '../wallet.class';

export function createFakeWallet(
  asset: AssetResponseInterface
): ExtendedWallet {
  return {
    address: null,
    asset,
    assetId: asset.id,
    balance: null,
    euroBalance: new Big(0),
  };
}

export function calculateTotalBalance(wallets: ExtendedWallet[]): Big {
  return wallets.reduce(
    (acc, wallet) => acc.add(wallet.euroBalance),
    new Big(0)
  );
}

export function prepareWalletList(
  wallets: ExtendedWallet[],
  assets: AssetResponseInterface[]
): ExtendedWallet[] {
  return assets.map(
    asset =>
      wallets.find(wallet => wallet.asset.symbol === asset.symbol) ??
      createFakeWallet(asset)
  );
}
