import { APPConfig } from '@app/shared/services/config/config.service';

declare var window: any;
class Environment extends APPConfig {
  protected loaded: boolean = false;
  constructor() {
    super();
    return new Proxy(this, {
      get: (target, name, value) => {
        if (typeof window.config !== 'undefined' && !this.loaded) {
          Object.assign(this, { ...window.config });
          window.config = undefined;
          this.loaded = true;
        }
        return Reflect.get(target, name, value);
      },
    });
  }
}

export const environment = new Environment();
