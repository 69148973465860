import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BasicObservableParams,
  BasicObservableService,
} from './abstract.basic.service';

@Injectable({
  providedIn: 'root',
})
export class AbstractStore extends BasicObservableService {
  get<T>(
    key: string,
    obParams: BasicObservableParams = {}
  ): Observable<T> {
    return this.getObservable(key, obParams);
  }

  set(key: string, payload: any = null): void {
    this.update(payload, key);
  }

  getAsEvent<T>(key: string, obParams: BasicObservableParams = { null: true, getElement: true }): Observable<T> {
    return this.get<T>(key, obParams);
  }
}
