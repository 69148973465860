import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { Big } from 'big.js';
import { ExtendedAsset } from '../assets-manager/asset.class';

export interface ExtendedWallet extends WalletResponseInterface {
  asset?: ExtendedAsset;
  euroBalance?: Big;
  euroRate?: Big;
  eurChange?: Big;
  percentChange?: Big;
}

export class EWallet implements ExtendedWallet {
  id: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  caasWallet: string = '';
  assetId: string = '';
  asset: ExtendedAsset = null;
  balance: string = '';
  address: string = '';
  user: UserResponseInterface = null;
  incomes: string = '';
  euroRate: Big = new Big(0);
  euroBalance: Big = new Big(0);
  percentChange?: Big;
  eurChange?: Big;

  constructor(wallet: WalletResponseInterface) {
    Object.assign(this, wallet);

    // FAKE WALLET
    if (!this.address) return;

    // EUR RATE
    this.euroRate = new Big(this.asset.rate.EUR);

    // EUR BALANCE
    this.euroBalance = new Big(this.balance).mul(this.euroRate);

    // EUR CHANGE
    this.eurChange = new Big(this.balance).mul(this.asset.eurChange);

    // PERCENT CHANGE
    this.percentChange = new Big(this.balance).mul(
      new Big(this.asset.marketData.percentChange24h)
    );
  }

  create(): ExtendedWallet {
    return {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      caasWallet: this.caasWallet,
      assetId: this.assetId,
      asset: this.asset,
      balance: this.balance,
      address: this.address,
      user: this.user,
      incomes: this.incomes,
      euroRate: this.euroRate,
      euroBalance: this.euroBalance,
      eurChange: this.eurChange,
      percentChange: this.percentChange,
    };
  }
}
