<ion-content
  class="ion-padding"
  *ngVar="{
    terms: onTerms$ | async,
    loading: onTermsLoading$ | async,
  } as state"
>
  @if (state.loading) {
    <app-loading-box></app-loading-box>
  } @else {
    <app-card-box [title]="'admin.policies.title' | translate"></app-card-box>
    <div [innerHTML]="state.terms.text"></div>
  }
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-row class="ion-justify-content-center ion-padding bg">
      <ion-col size="12">
        <ion-button expand="full" color="primary" (click)="updateTerms()">
          {{ 'admin.operations.continue' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>
