import { environment } from '@environments/environment';
import { AuthConfigErrors } from '../interfaces/auth/auth.error.enum';
import { TokenDecoded } from '../interfaces/auth/token';
import { secondsToUnit, TimeUnit } from './time';

export function parseToken<T = TokenDecoded>(encodedToken: string): T | null {
  try {
    const splitToken = encodedToken?.split('.');
    if (splitToken.length < 2) return null;
    let [, token] = splitToken;
    return JSON.parse(atob(token)) as T;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getTokenExp(encodedToken: string): number {
  return parseToken<TokenDecoded>(encodedToken)?.exp || 0;
}

export function isTSExpired(expiration: number): boolean {
  const now = Math.floor(Date.now() / 1000);
  return expiration <= now;
}

export function reduceExp(expiration: number): number {
  const time = environment.token.reduceTime.time;
  const unit = environment.token.reduceTime.unit;

  const timeToReduce: number = secondsToUnit(Number(time), TimeUnit[unit]);

  if (!timeToReduce) {
    throw new Error(AuthConfigErrors.REDUCE_TIME);
  }

  return expiration - timeToReduce;
}
