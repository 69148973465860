import { CommonModule } from '@angular/common';
import { Component, inject, viewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicComponentsModule } from '@app/shared/modules/ionic-components/ionic-components.module';
import { AuthService } from '@app/shared/services/auth/auth.service';
import {
  HeaderService,
  MenuListType,
} from '@app/shared/services/header/header.service';
import { WalletsManagerService } from '@app/shared/services/wallets-manager/wallets-manager.service';
import { IonMenu, NavController } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { MenuListPipe } from './pipes/menu-list.pipe';
import { MENU_DASHBOARD } from './utils/menu.dashboard';
import { MENU_WALLET } from './utils/menu.wallet';

export interface MenuList {
  label: string;
  route: string;
  translateParams?: Record<string, string>;
}

@Component({
  selector: 'app-header-menu',
  standalone: true,
  imports: [
    IonicComponentsModule,
    TranslateModule,
    CommonModule,
    MenuListPipe,
    RouterModule,
  ],
  templateUrl: './header-menu.component.html',
})
export class HeaderMenuComponent {
  #navController = inject(NavController);
  #authService = inject(AuthService);

  headerService = inject(HeaderService);
  walletsManager = inject(WalletsManagerService);

  menu = viewChild.required<IonMenu>('menu');

  menuList: Record<MenuListType, MenuList[]> = {
    [MenuListType.DASHBOARD]: MENU_DASHBOARD,
    [MenuListType.WALLET]: MENU_WALLET,
  };

  menuListType$: Observable<MenuList[]> = this.headerService.menuListType$.pipe(
    map(type => this.menuList[type])
  );

  navigateTo(route: string) {
    this.menu().close();
    this.#navController.navigateForward(route);
  }

  exit(): void {
    this.menu().close();
    this.#authService.logout();
    this.#navController.navigateRoot('/');
  }
}
