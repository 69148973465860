import { environment } from '@environments/environment';
import { UserInterfaceThemes } from '../services/theme/theme.service';

export const getPreferredScheme = () =>
  (window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches
    ? 'dark'
    : 'light'
  ).toUpperCase();

export const getDefaultTheme = (): UserInterfaceThemes =>
  UserInterfaceThemes[
    environment.defaultTheme.toUpperCase() === 'AUTO'
      ? getPreferredScheme().toUpperCase()
      : environment.defaultTheme.toUpperCase()
  ];
