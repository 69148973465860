import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import { PolicyResponseInterface } from '@interfaces/policy/policy.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class PoliciesService extends AbstractCrudService<
  PolicyResponseInterface,
  QueryParamsInterface
> {
  protected override feature = 'policies';
  protected override endPoint = '/policies/';
}
