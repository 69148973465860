<div class="ion-page" id="main-content">
  <ion-header>
    <ion-toolbar mode="ios">
      @if (canGoBack$ | async) {
        <ion-buttons slot="start">
          <ion-button (click)="goBack()">
            <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon
          ></ion-button>
        </ion-buttons>
      }

      @if (canGoBack$ | async) {
        <!-- WALLET TITLE -->
        <ion-title style="user-select: none" [@fadeIn]>{{
          title$ | async
        }}</ion-title>
      } @else {
        <!-- DASHBOARD LOGO -->
        <ion-title style="user-select: none" [@fadeIn]>
          <ion-img
            [src]="logoUrl"
            alt="Onyze logo"
            style="width: 100px; height: 26px; justify-self: center"
          ></ion-img
        ></ion-title>
      }

      <ion-buttons slot="end">
        <ion-menu-button *ngIf="showMenuButton$ | async"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-router-outlet></ion-router-outlet>
  </ion-content>
</div>

<app-header-menu></app-header-menu>
