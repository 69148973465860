import { inject, Injectable } from '@angular/core';

import { DEFAULT_CRITERIA } from '@app/shared/components/crud-base/crud-base.component';
import { TransactionQueryParamsInterface } from '@interfaces/user/wallet/transaction/transaction.query.params.interface';
import { TransactionResponseInterface } from '@interfaces/user/wallet/transaction/transaction.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService extends AbstractCrudService<
  TransactionResponseInterface,
  TransactionQueryParamsInterface
> {
  protected override feature = 'transactions';
  protected override endPoint = `/users/%s/wallets/%s/transactions/`;

  #authService = inject(AuthService);

  getTransactions(
    walletId: string,
    params: TransactionQueryParamsInterface = DEFAULT_CRITERIA,
    stack: boolean = true
  ): void {
    const userId = this.#authService.user.id;
    this.getAll(params, [userId, walletId], '', !stack);
  }

  getTransaction(walletId: string, txId: string): void {
    const userId = this.#authService.user.id;
    this.getOne(txId, [userId, walletId]);
  }
}
