import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthRefreshService } from '../services/auth/auth-refresh.service';
import { isApiKeyRequest, isApiRequest } from './api-request.filter';

export function authInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthRefreshService);
  const authorizedRequest = Boolean(request.headers.get('Authorization'));

  // Si la petición ya contiene cabeceras AUTH no hace falta añadirlas
  // Dejamos pasar peticiones que no apunten a back.
  if (authorizedRequest || !isApiRequest(request.url)) {
    return next(request);
  }

  const haveTokens = !!authService.tokens?.accessToken;
  const apiKeyRequest = isApiKeyRequest(request.url, request.method);

  const authValue =
    !apiKeyRequest && haveTokens
      ? `Bearer ${authService.tokens.accessToken}`
      : `ApiKey ${authService.clientCredentials}`;

  const authHeader = {
    setHeaders: {
      Authorization: authValue,
    },
  };

  request = request.clone(authHeader);

  return next(request).pipe(
    catchError(error => {
      const { status: _status } = error.error;
      /*       if (status === 401 && error.url.includes('auth'))
        authService.setError(AuthErrors.INVALID_TOKEN); */
      return throwError(() => error);
    })
  );
}
