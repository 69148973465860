import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APIError } from '@app/shared/interfaces/abstract/error';
import {
  AbstractCrudService,
  CRUDEvents,
} from '@app/shared/services/abstract/abstract-crud.service';
import {
  HttpUpdateMethods,
  IHeaders,
} from '@app/shared/services/abstract/abstract.service';
import { ModelInterface } from '@interfaces/global/model.interface';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

export const DEFAULT_CRITERIA: QueryParamsInterface = {
  limit: 5,
  page: 1,
  sortColumn: 'updatedAt',
  sortOrder: 'DESC',
};

export interface RoleEvents<T> {
  create?: Observable<T>;
  read?: Observable<T>;
  readAll?: Observable<T[]>;
  update?: Observable<T>;
  delete?: Observable<T>;
  error?: Observable<APIError>;
}

@UntilDestroy({ arrayName: 'listeners' })
@Component({
  selector: 'app-crud-base',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
})
export class CrudBaseComponent<
  T extends ModelInterface,
  Q extends QueryParamsInterface,
> {
  listeners: Subscription[] = [];

  paginator: IHeaders & {
    totalPages?: number;
  } = {
    limit: DEFAULT_CRITERIA.take,
    page: 0,
    total: 0,
    totalPages: 0,
  };

  loading$: Observable<boolean>;
  items$: Observable<T[]>;

  constructor(protected abstractService: AbstractCrudService<T, Q>) {
    this.prepareListeners({
      create: this.abstractService.onCreate$(),
      readAll: this.abstractService.onReadAll$(),
      read: this.abstractService.onRead$(),
      update: this.abstractService.onUpdate$(),
      delete: this.abstractService.onDelete$(),
      error: this.abstractService.onError$(CRUDEvents.ALL),
    });
  }

  protected prepareListeners(events: RoleEvents<T>): void {
    Object.entries(events).forEach(([key, event]) => {
      const method = 'on' + key.charAt(0).toUpperCase() + key.slice(1);
      this.listeners.push(
        (event as Observable<any>).subscribe(this[method].bind(this))
      );
      console.log(`[LISTENER] ${key} -> ${method}`);
    });

    this.loading$ = this.abstractService.onLoading$(CRUDEvents.ALL);
    this.items$ = this.abstractService.onReadAll$();
  }

  protected create(model: Partial<T>): void {
    console.log('[REQUEST] Create ->', model);
    this.abstractService.create(model);
  }

  protected delete(id: string): void {
    console.log('[REQUEST] Delete ->', id);
    this.abstractService.delete(id);
  }

  protected update(model: Partial<T>): void {
    console.log('[REQUEST] Update ->', model);
    this.abstractService.update(HttpUpdateMethods.PUT, model.id, model);
  }

  protected readAll(criteria = DEFAULT_CRITERIA): void {
    console.log('[REQUEST] Read All ->', criteria);
    const params = { ...DEFAULT_CRITERIA, ...criteria } as Q;
    this.abstractService.getAll(params);
  }

  protected onCreate(model: T): void {
    console.log('[RESPONSE] Created ->', model);
    this.readAll();
  }

  protected async onReadAll(model: T[]): Promise<void> {
    console.log('[RESPONSE] Readed All ->', model);

    this.paginator = await this.abstractService.getHeadersSync(
      CRUDEvents.READALL
    );

    this.paginator.limit = DEFAULT_CRITERIA.take;

    this.paginator.totalPages = Math.ceil(
      this.paginator.total / this.paginator.limit
    );
  }

  protected onRead(model: T): void {
    console.log('[RESPONSE] Read ->', model);
  }

  protected onUpdate(model: T): void {
    console.log('[RESPONSE] Updated ->', model);
  }

  protected onDelete(model: T): void {
    console.log('[RESPONSE] Deleted ->', model);
  }

  protected onError(error: APIError): void {
    console.log('[RESPONSE] Error ->', error);
  }

  protected backPage(): void {
    const page = this.paginator.page - 1;
    console.log('[REQUEST] Back Page ->', page);
    this.readAll({ page });
  }

  protected nextPage(): void {
    const page = this.paginator.page + 1;
    console.log('[REQUEST] Next Page ->', page);
    this.readAll({ page });
  }
}
