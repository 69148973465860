import { Injectable } from '@angular/core';
import { RateResponseInterface } from '@interfaces/asset/rate/rate.response.interface';
import { StatsQueryParamsInterface } from '@interfaces/stats/stats.query.params.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class StatsService extends AbstractCrudService<
  RateResponseInterface,
  StatsQueryParamsInterface
> {
  protected override feature = 'stats';
  protected override endPoint = `/stats/`;
}
