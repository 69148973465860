import { ExtendedWallet } from '@app/shared/services/wallets-manager/wallet.class';
import { MenuList } from '../header-menu.component';

export function generateMenuItems(
  menuList: MenuList[],
  wallet: ExtendedWallet
): MenuList[] {
  if (!menuList || !wallet) return menuList;

  const assetId: string = wallet.asset.id;
  const assetName: string = wallet.asset.name;
  const walletId: string = wallet.id;

  const parsedMenuList = menuList.reduce((acc, menu) => {
    acc.push({
      label: menu.label,
      route: menu.route
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':walletId', walletId),
      translateParams: Object.keys(menu?.translateParams ?? []).reduce(
        (acc, key) => {
          acc[key] = menu.translateParams[key]
            .replace(':assetId', assetId)
            .replace(':assetName', assetName)
            .replace(':walletId', walletId);
          return acc;
        },
        {}
      ),
    });
    return acc;
  }, []);
  return parsedMenuList;
}
