import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractError } from '@app/shared/interfaces/abstract/error';
import {
  AbstractEvent,
  GlobalMessage,
} from '@app/shared/interfaces/abstract/events';
import { getElement } from '@app/shared/utils/abstract.rx.pipes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AbstractStore } from '../../abstract/abstract-store.service';
import { ErrorsService } from '../error/errors.service';
import { blacklistedSections } from './errors-blacklist';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class GlobalErrorsService {
  private errorListener: Subscription;
  private errorSection: string = 'errors';

  constructor(
    private store: AbstractStore,
    private translate: TranslateService,
    private errorsService: ErrorsService,
    private router: Router
  ) {
    this.start();
  }

  start(): void {
    this.stop();
    this.errorListener = this.errorsService
      .getObservable(this.errorSection)
      .pipe(untilDestroyed(this), getElement())
      .subscribe(this.handleErrors.bind(this));
  }

  stop(): void {
    if (this.errorListener) this.errorListener.unsubscribe();
  }

  private handleErrors(error: AbstractError): void {
    const { payload, section, id } = error;

    const [, feature] = section.split('.');
    const isBlacklisted = blacklistedSections.includes(feature);

    if (isBlacklisted) return;

    const message = this.translate.instant(payload);

    console.log('GLOBAL ERROR HANDLED: ', error);
    console.log(`[${feature.toUpperCase()}]: ${message}`);

    if (feature === 'auth') {
      const [, , type] = section.split('.');
      if (type === 'invalid' || type === 'expired') {
        const messageEvent: GlobalMessage = {
          message,
          title: 'Sesión',
          severity: 'error',
          life: 5000,
        };
        this.store.set(AbstractEvent.GLOBAL_MESSAGE, messageEvent);
        //this.router.navigateByUrl('/auth/login');
      }
    }

    this.errorsService.remove(id);
  }

  checkBlacklist(section: string): boolean {
    return !!blacklistedSections.find(feature => section.includes(feature));
  }
}
