import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { errorInterceptor } from '@shared/interceptors/error.interceptor';
import {
  ConfigService,
  initConfig,
} from '@shared/services/config/config.service';
import { CryptoServiceModule } from '@shared/services/crypto/crypto.module';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { routes } from './app.routes';
import { authInterceptor } from './shared/interceptors/auth.interceptor';
import { refreshInterceptor } from './shared/interceptors/refresh.interceptor';
import { AbstractServicesModules } from './shared/services/abstract-services/abstract-services.module';
import { AuthRefreshService } from './shared/services/auth/auth-refresh.service';
import { HeaderService } from './shared/services/header/header.service';
import { TermsManagerModule } from './shared/services/terms-manager/terms-manager.module';
import { ThemeService } from './shared/services/theme/theme.service';
import { ToastService } from './shared/services/toast/toast.service';
import { WalletsManagerService } from './shared/services/wallets-manager/wallets-manager.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ConfigServiceInit = [
  ConfigService,
  {
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    multi: true,
    deps: [ConfigService],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    ...ConfigServiceInit,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideRouter(routes),
    provideIonicAngular({
      mode: 'md',
      spinner: 'dots',
      refreshingSpinner: 'dots',
    }),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors([errorInterceptor, refreshInterceptor, authInterceptor])
    ),
    provideCharts(withDefaultRegisterables()),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'es',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      CryptoServiceModule.forRoot(),
      AbstractServicesModules.forRoot(),
    ]),
    TermsManagerModule,
    AuthRefreshService,
    WalletsManagerService,
    ThemeService,
    HeaderService,
    ToastService,
  ],
};
