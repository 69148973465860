import { inject, Injectable } from '@angular/core';
import { TermsModalComponent } from '@app/shared/components/terms-modal/terms-modal.component';
import { ModalController } from '@ionic/angular/standalone';
import { v4 as UUID } from 'uuid';
import { PoliciesService } from '../abstract-services/policies/policies.service';
import { UsersService } from '../abstract-services/users/users.service';
import { CRUDEvents } from '../abstract/abstract-crud.service';
import { HttpUpdateMethods } from '../abstract/abstract.service';
import { AuthRefreshService } from '../auth/auth-refresh.service';

@Injectable({
  providedIn: 'root',
})
export class TermsManagerService {
  #policiesService = inject(PoliciesService);
  #authService = inject(AuthRefreshService);
  #usersService = inject(UsersService);

  constructor(private modalCtrl: ModalController) {}

  onTerms$ = this.#policiesService.onRead$();
  onTermsUpdate$ = this.#usersService.onUpdate$();
  onTermsLoading$ = this.#policiesService.onLoading$(CRUDEvents.READ);
  onTermsError$ = this.#policiesService.onError$(CRUDEvents.READ);

  modal!: HTMLIonModalElement | undefined;

  async showTerms(): Promise<void> {
    if (this.modal) return;

    this.modal = await this.modalCtrl.create({
      component: TermsModalComponent,
    });

    this.modal.present();

    await this.modal.onDidDismiss();

    this.modal = undefined;
  }

  updateTerms(): void {
    const termsModel = {
      generalConditions: new Date(),
      externalReference: UUID(),
    };

    this.#usersService.update(
      HttpUpdateMethods.PATCH,
      this.#authService.user.id,
      termsModel
    );
  }

  getTerms(): void {
    this.#policiesService.getOne(this.#authService.terms.termId);
  }
}
