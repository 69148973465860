import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtendedRecord } from '@app/shared/interfaces/abstract/extended';
import { environment } from '@environments/environment';
import { ModelInterface } from '@interfaces/global/model.interface';
import { QueryParamsInterface } from '@interfaces/global/query.params.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { vsprintf } from 'sprintf-js';
import { ErrorsService } from '../abstract-services/error/errors.service';
import { AbstractHelper } from './abstract-helper.class';

export enum HttpUpdateMethods {
  PUT,
  PATCH,
}

export interface IHeaders {
  limit?: number;
  page?: number;
  total?: number;
}

interface IDataStore<T> {
  [key: string]: T;
}

interface IBehaviorSubjects<T> {
  [key: string]: BehaviorSubject<T>;
}

interface IObservable<T> {
  [key: string]: Observable<T>;
}

type AbstractStore<T> = T | null;

export interface ObservableParams {
  arrayEmpty?: boolean;
  onlyFirst?: boolean;
  null?: boolean;
  getElement?: boolean;
  cleanSection?: boolean;
}

@Injectable()
export abstract class AbstractService<
  IModel extends ModelInterface,
  IOptions extends QueryParamsInterface,
> {
  protected options!: IOptions;
  protected errorsSection: string = '';
  protected baseUrl = environment.api.url;
  protected endPoint: string = '';

  protected dataStore: AbstractStore<IDataStore<IModel[]>> = {};
  protected dataStoreHeaders: AbstractStore<IDataStore<IHeaders>> = {};

  protected behaviorSubjects: AbstractStore<IBehaviorSubjects<IModel[]>> = {};
  protected behaviorSubjectsHeaders: AbstractStore<
    IBehaviorSubjects<IHeaders>
  > = {};
  protected behaviorSubjectsUpdating: AbstractStore<
    IBehaviorSubjects<boolean>
  > = {};

  protected observable: AbstractStore<IObservable<IModel[]>> = {};
  protected observableHeaders: AbstractStore<IObservable<IHeaders>> = {};
  protected observableUpdating: AbstractStore<IObservable<boolean>> = {};

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    this.checkSection();
  }

  protected static parseResponse(response) {
    return {
      body: response.body.data,
      headers: {
        total: Number(response.headers.get('count')),
        page: Number(response.headers.get('page')),
        limit: Number(response.headers.get('limit')),
      },
    };
  }

  protected static setParams(params) {
    let httpParams = new HttpParams();

    Object.keys(params).map(value => {
      httpParams = httpParams.set(value, params[value]);
    });

    return httpParams;
  }

  protected checkSection(section: string = 'standard', force = false) {
    if (force || !this.dataStore?.[section]) {
      this.dataStore[section] = [] as IModel[];
      this.dataStoreHeaders[section] = {} as IHeaders;

      this.behaviorSubjects[section] = new BehaviorSubject(
        null
      ) as BehaviorSubject<IModel[]>;
      this.behaviorSubjectsHeaders[section] = new BehaviorSubject(
        null
      ) as BehaviorSubject<IHeaders>;
      this.behaviorSubjectsUpdating[section] = new BehaviorSubject(null);

      this.observable[section] = this.behaviorSubjects[section].asObservable();
      this.observableHeaders[section] =
        this.behaviorSubjectsHeaders[section].asObservable();

      this.observableUpdating[section] =
        this.behaviorSubjectsUpdating[section].asObservable();
    }
    return true;
  }

  protected cleanSection(section = 'standard') {
    this.checkSection(section);
    this.dataStore[section] = [] as IModel[];
    this.behaviorSubjects[section].next(null);
    this.endUpdating(section);
  }

  protected cleanSectionHeaders(section = 'standard') {
    this.checkSection(section);
    this.dataStoreHeaders[section] = {} as IHeaders;
    this.behaviorSubjectsHeaders[section].next(null);
  }

  public getObservable<T = IModel>(
    section = 'standard',
    params: ObservableParams = {}
  ): Observable<T | T[]> {
    this.checkSection(section);

    const observable = (
      this.observable[section] as unknown as Observable<T>
    ).pipe(
      filter(data => {
        // not nulls
        if (!params?.null && data === null) {
          return false;
        }

        // array -> not empty (length > 0)
        if (!params?.arrayEmpty && Array.isArray(data) && data.length === 0) {
          return false;
        }

        return true;
      }),
      map(data => {
        if (params?.getElement && Array.isArray(data)) {
          return (data as T[]).at(-1) as T;
        }

        return data;
      }),
      tap(() => {
        if (params?.cleanSection) {
          this.cleanSection(section);
        }
      })
    );

    return params?.onlyFirst ? observable.pipe(first()) : observable;
  }

  protected getObservableHeaders(section = 'standard'): Observable<IHeaders> {
    this.checkSection(section);
    return this.observableHeaders[section];
  }

  protected getObservableUpdating(section = 'standard'): Observable<boolean> {
    this.checkSection(section);
    return this.observableUpdating[section];
  }

  protected next(section): void {
    this.behaviorSubjectsHeaders[section].next(
      Object.assign({}, this.dataStoreHeaders)[section]
    );

    this.behaviorSubjects[section].next(
      Object.assign({}, this.dataStore)[section]
    );
  }

  protected changeUpdatingStatus(
    section: string = null,
    param: boolean = false
  ): void {
    if (section) {
      this.behaviorSubjectsUpdating[section].next(param);
    } else {
      Object.keys(this.dataStore).forEach(subSection => {
        this.changeUpdatingStatus(subSection, param);
      });
    }
  }

  protected startUpdating(section: string = 'standard'): void {
    this.changeUpdatingStatus(section, true);
  }

  protected endUpdating(section: string = 'standard'): void {
    this.changeUpdatingStatus(section, false);
  }

  protected queryPost(
    model: any,
    section: string | string[] = 'standard',
    endpointUrl: string
  ) {
    const sections = typeof section === 'string' ? [section] : section;
    sections.forEach(sec => {
      this.checkSection(sec);
      this.startUpdating(sec);
    });

    this.http.post<ExtendedRecord<IModel>>(endpointUrl, model).subscribe({
      next: (resp: ExtendedRecord<IModel>) => {
        const data = resp.data;
        sections.forEach(sec => {
          this.dataStore[sec].push(data as IModel);
          this.next(sec);
          this.endUpdating(sec);
        });
      },
      error: error => {
        this.errorsService.create(`${this.errorsSection}.${section}`, {
          payload: error,
        });
        sections.forEach(sec => {
          this.endUpdating(sec);
        });
      },
    });
  }

  protected queryPatch(
    model: any,
    _section = 'standard',
    addToSection = 'standard',
    endpointUrl: string,
    token: string = ''
  ) {
    let added = false;
    this.startUpdating(_section);

    const headers = new HttpHeaders();

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    this.http
      .patch<ExtendedRecord<IModel>>(endpointUrl, model, { headers })
      .subscribe({
        next: (resp: ExtendedRecord<IModel>) => {
          const data = resp.data;
          // Walk around all sections
          Object.keys(this.dataStore).forEach(section => {
            // Walk around all objects inside the section
            this.dataStore[section].forEach((t, i) => {
              if (t.id === data.id) {
                if (addToSection === section) {
                  added = true;
                }
                this.dataStore[section][i] = data as IModel;
                this.next(section);
              }
            });
          });

          // If it's not added, add it.

          if (!added && addToSection !== null) {
            this.checkSection(addToSection);
            this.dataStore[addToSection].push(data as IModel);
            this.next(addToSection);
          }

          this.dataStore[_section].push(data as IModel);
          this.next(_section);
          this.endUpdating(_section);
        },
        error: error => {
          this.errorsService.create(`${this.errorsSection}.${_section}`, {
            payload: error,
          });
          this.endUpdating(_section);
        },
      });
  }

  protected queryPut(
    model: any,
    _section = 'standard',
    addToSection = 'standard',
    endpointUrl: string,
    token: string = ''
  ) {
    let added = false;
    this.startUpdating(_section);

    const headers = {};

    if (token) {
      Object.assign(headers, {
        Authorization: `Bearer ${token}`,
      });
    }

    this.http
      .put<ExtendedRecord<IModel>>(endpointUrl, model, { headers })
      .subscribe({
        next: (resp: ExtendedRecord<IModel>) => {
          const data = resp.data;
          // Walk around all sections
          Object.keys(this.dataStore).forEach(section => {
            // Walk around all objects inside the section
            this.dataStore[section].forEach((t, i) => {
              if (t.id === data.id) {
                if (addToSection === section) {
                  added = true;
                }
                this.dataStore[section][i] = data as IModel;
                this.next(section);
              }
            });
          });

          // If it's not added, add it.

          if (!added && addToSection !== null) {
            this.checkSection(addToSection);
            this.dataStore[addToSection].push(data as IModel);
            this.next(addToSection);
          }

          this.dataStore[_section].push(data as IModel);
          this.next(_section);
          this.endUpdating(_section);
        },
        error: error => {
          this.errorsService.create(`${this.errorsSection}.${_section}`, {
            payload: error,
          });
          this.endUpdating(_section);
        },
      });
  }

  protected queryGetAll(
    externalOptions: IOptions = {} as IOptions,
    section = 'standard',
    endpointUrl: string = null,
    replace: boolean = true
  ) {
    const options = Object.assign({}, this.options, externalOptions);
    const params = AbstractService.setParams(options);
    this.checkSection(section);
    this.startUpdating(section);
    this.http
      .get(endpointUrl, { observe: 'response', params })
      .pipe(map(resp => AbstractService.parseResponse(resp)))
      .subscribe({
        next: resp => {
          if (replace) {
            this.dataStore[section] = resp.body;
          } else {
            this.dataStore[section] = this.dataStore[section].concat(resp.body);
          }
          this.dataStoreHeaders[section] = resp.headers;
          this.next(section);
          this.endUpdating(section);
        },
        error: error => {
          this.errorsService.create(`${this.errorsSection}.${section}`, {
            payload: error,
          });
          this.endUpdating(section);
        },
      });
  }

  protected queryGetOne(
    section = 'standard',
    endpointUrl: string = null,
    push = true
  ) {
    this.checkSection(section);
    this.startUpdating(section);

    this.http.get<ExtendedRecord<IModel>>(endpointUrl).subscribe({
      next: (resp: ExtendedRecord<IModel>) => {
        const data = resp.data;
        let found = false;

        this.dataStore[section].forEach((item, index) => {
          if (item.id === data.id) {
            this.dataStore[section][index] = data as IModel;
            found = true;
          }
        });

        if (!found) {
          if (push === true) {
            this.dataStore[section].push(data as IModel);
          } else {
            this.dataStore[section].unshift(data as IModel);
          }
        }
        this.dataStore[section] = [data];
        this.next(section);
        this.endUpdating(section);
      },
      error: error => {
        this.errorsService.create(`${this.errorsSection}.${section}`, {
          payload: error,
        });
        this.endUpdating(section);
      },
    });
  }

  protected queryDelete(
    hash: string,
    endpointUrl: string = null,
    section: string = 'standard'
  ) {
    this.startUpdating();
    this.http.delete(endpointUrl).subscribe({
      next: response => {
        // Walk around all sections
        (Object.keys(this.dataStore) || []).forEach(_section => {
          // Walk around all objects inside the section
          (this.dataStore[_section] || []).forEach((t, i) => {
            if (t.id === hash) {
              this.dataStore[_section].splice(i, 1);
              this.next(_section);
            }
          });
        });

        this.dataStore[section] = (response as any)?.data;
        this.next(section);
        this.endUpdating();
      },
      error: error => {
        this.errorsService.create(`${this.errorsSection}.${section}`, {
          payload: error,
        });
        this.endUpdating();
      },
    });
  }

  protected _create(
    object: IModel,
    section: string | string[] = 'standard',
    urlParams: string[] = [],
    url: string = null
  ): AbstractHelper<IModel> {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}`;

    this.queryPost(object, section, endpointUrl);

    return new AbstractHelper(section, this);
  }

  protected _getAll(
    externalOptions: IOptions = {} as IOptions,
    section = 'standard',
    urlParams: string[] = [],
    url: string = null,
    replace: boolean = true
  ): AbstractHelper<IModel> {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}`;

    this.queryGetAll(externalOptions, section, endpointUrl, replace);

    return new AbstractHelper(section, this);
  }

  protected _getOne(
    hash: string,
    section = 'standard',
    urlParams: string[] = [],
    url: string = null,
    push: boolean = true
  ): AbstractHelper<IModel> {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}${hash}`;

    this.queryGetOne(section, endpointUrl, push);

    return new AbstractHelper<IModel>(section, this);
  }

  protected _update(
    method: HttpUpdateMethods,
    hash: string,
    model: IModel,
    section: string = 'standard',
    urlParams: string[] = [],
    url: string = null,
    token: string = ''
  ): AbstractHelper<IModel> {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}${hash}`;
    if (method === HttpUpdateMethods.PATCH) {
      this.queryPatch(model, section, '', endpointUrl, token);
    } else {
      this.queryPut(model, section, '', endpointUrl, token);
    }
    return new AbstractHelper(section, this);
  }

  protected _delete(
    hash: string,
    section: string = 'standard',
    urlParams: string[] = [],
    url: string = null
  ): AbstractHelper<IModel> {
    url = url || this.endPoint;
    url = vsprintf(url, urlParams);
    const endpointUrl = `${this.baseUrl}${url}${hash}`;
    this.queryDelete(hash, endpointUrl, section);
    return new AbstractHelper(section, this);
  }
}
