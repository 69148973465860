import { Injectable } from '@angular/core';

import { UserQueryParamsInterface } from '@interfaces/user/user.query.params.interface';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { lastValueFrom, Observable } from 'rxjs';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends AbstractCrudService<
  UserResponseInterface,
  UserQueryParamsInterface
> {
  protected override feature = 'users';
  protected override endPoint = `/users/`;

  getUserByIdSync(
    userId: string,
    section: string = 'async-read-one'
  ): Promise<UserResponseInterface> {
    this._getOne(userId, section, [], null, true);
    return lastValueFrom(
      this.getObservable<UserResponseInterface>(section, {
        cleanSection: true,
        onlyFirst: true,
        getElement: true,
      }) as Observable<UserResponseInterface>
    );
  }
}
