import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedWallet } from '@app/shared/services/wallets-manager/wallet.class';
import { MenuList } from '../header-menu.component';
import { generateMenuItems } from '../utils/menu.config';

@Pipe({
  name: 'menuList',
  standalone: true,
})
export class MenuListPipe implements PipeTransform {
  transform(value: MenuList[], wallet: ExtendedWallet): MenuList[] {
    return generateMenuItems(value, wallet);
  }
}
