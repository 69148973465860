import { Injectable } from '@angular/core';
import { PurchaseQueryParamsInterface } from '@interfaces/user/purchase/purchase.query.params.interface';
import { PurchaseResponseInterface } from '@interfaces/user/purchase/purchase.response.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable({
  providedIn: 'root',
})
export class PurchasesService extends AbstractCrudService<
  PurchaseResponseInterface,
  PurchaseQueryParamsInterface
> {
  protected override feature = 'stats';
  protected override endPoint = `/users/%s/wallets/%s/purchases/`;

  makeOperation(
    userId: string,
    walletId: string,
    operation: PurchaseResponseInterface
  ): void {
    this.create(operation, [userId, walletId]);
  }
}
