import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { PublicGuard } from './shared/guards/public.guard';

// prettier-ignore
export const routes: Routes = [
  {
    path: '',
    canActivate: [PublicGuard],
    loadChildren: () =>
      import('./core/public/public.routes').then(m => m.PublicRoutingModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/admin/admin.routes').then(m => m.AdminRoutingModule),
  },
];
