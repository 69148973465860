import { AuthConfigErrors } from '@app/shared/interfaces/auth/auth.error.enum';
import { secondsToUnit, TimeUnit } from '@app/shared/utils/time';
import { environment } from '@environments/environment';
import { interval, Observable, Subscription } from 'rxjs';

export class Monitor {
  // Token Monitor
  private monitor!: Subscription;
  private monitorInterval$!: Observable<number>;
  monitorCallback: () => void;

  constructor(private cb: () => void) {
    this.monitorCallback = cb;
  }

  startMonitor(): void {
    this.stopMonitor();
    const time = environment.token.autoRefresh.time;
    const unit = environment.token.autoRefresh.unit;

    const intervalTime = secondsToUnit(Number(time), TimeUnit[unit]);

    if (!intervalTime) {
      throw new Error(AuthConfigErrors.REFRESH_TIME);
    }

    this.monitorInterval$ = interval(intervalTime * 1000);
    this.monitor = this.monitorInterval$.subscribe(() =>
      this.monitorCallback()
    );
  }

  stopMonitor(): void {
    if (this.monitor) this.monitor.unsubscribe();
  }
}
