import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { AbstractEvent } from '@app/shared/interfaces/abstract/events';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractStore } from '../abstract/abstract-store.service';

export enum UserInterfaceThemes {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme: UserInterfaceThemes = UserInterfaceThemes.LIGHT;
  #document = inject(DOCUMENT);

  constructor(private store: AbstractStore) {
    this.initTheme();
  }

  private initTheme(): void {
    this.store
      .getAsEvent<UserInterfaceThemes>(AbstractEvent.SET_THEME, {
        getElement: true,
      })
      .pipe(untilDestroyed(this))
      .subscribe(this.onThemeChange.bind(this));
  }

  private onThemeChange(theme: UserInterfaceThemes): void {
    const shouldAdd = theme === UserInterfaceThemes.DARK;

    this.#document.documentElement.classList.toggle(
      'ion-palette-dark',
      shouldAdd
    );

    this.currentTheme = theme;
  }

  setTheme(theme: UserInterfaceThemes): void {
    this.store.set(AbstractEvent.SET_THEME, theme.toUpperCase());
  }
}
